<template>
    <vx-card :title="'View Item Requested (Complete Picking)'">
        <div class="vx-row mb-6" style="width: 50%">
            <vs-button class="ml-4 mt-2" color="danger" icon-pack="feather" icon="icon-arrow-left"
                @click="handleBack()">Back</vs-button>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Canvas Item Request Code</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="code" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Canvas Item Request Picking Code</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="pickCode" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Territory</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="selectedTerritory" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Warehouse</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="selectedWarehouse" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Sales</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="selectedSales" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Loading Date</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="formatDate(loadingDate)" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6">
            <div class="vx-col w-full">
                <table width="100%" class="table table-bordered">
                    <thead class="vs-table--thead">
                        <tr>
                            <th rowspan="2" style="vertical-align: middle; text-align:center; width: 22%">SKU Description</th>
                            <th colspan="5" style="vertical-align: middle; text-align:center;">Suggestion</th>
                            <th colspan="5" style="vertical-align: middle; text-align:center;">Actual</th>
                        </tr>
                        <tr>
                            <th style="vertical-align: middle; text-align:center; width: 7%">HU</th>
                            <th style="vertical-align: middle; text-align:center; width: 3%">Qty</th>
                            <th style="vertical-align: middle; text-align:center; width: 10%">Storage Area</th>
                            <th style="vertical-align: middle; text-align:center; width: 12%">Batch - Exp. Date</th>
                            <th style="vertical-align: middle; text-align:center; width: 7%;">HU</th>
                            <th style="vertical-align: middle; text-align:center; width: 3%;">Qty</th>
                            <th style="vertical-align: middle; text-align:center; width: 10%;">Storage Area</th>
                            <th style="vertical-align: middle; text-align:center; width: 12%;">Batch - Exp. Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-for="(skuLine, index) in skuLines">
                            <tr style="padding-bottom: 5px" :key="index">
                                <td class="td vs-table--td align-top" style="padding: 5px"
                                    :rowspan="firstRowSpan(index)">
                                    {{ skuLine.sku_code }} - {{ skuLine.item_name }}
                                </td>

                                <!-- first row - suggestion -->
                                <td class="td vs-table--td align-top" style="padding: 5px">
                                    {{ skuLines[index].suggestion_lines[0].unit }}
                                </td>
                                <td class="td vs-table--td align-top" style="padding: 5px">
                                    {{ skuLines[index].suggestion_lines[0].quantity }}
                                </td>
                                <td class="td vs-table--td align-top" style="padding: 5px">
                                    {{ skuLines[index].suggestion_lines[0].storage_code }}
                                </td>
                                <td class="td vs-table--td align-top" style="padding: 5px">
                                    {{ skuLines[index].suggestion_lines[0].batch }}
                                </td>

                                <!-- first actual lines -->
                                <td class="td vs-table--td" style="padding: 5px">
                                    {{ skuLines[index].actual_lines[0].selected_hu }}
                                </td>
                                <td class="td vs-table--td" style="padding: 5px">
                                    {{ skuLines[index].actual_lines[0].qty }}
                                </td>
                                <td class="td vs-table--td" style="padding: 5px">
                                    {{ skuLines[index].actual_lines[0].selected_storage_area }}
                                </td>
                                <td class="td vs-table--td" style="padding: 5px">
                                    {{ skuLines[index].actual_lines[0].batch_number }}
                                </td>
                            </tr>

                            <!-- added lines -->
                            <template v-for="idx in skuLines[index].suggestion_lines.length > skuLines[index].actual_lines.length ? skuLines[index].suggestion_lines.length : skuLines[index].actual_lines.length">
                                <tr style="padding-bottom: 5px" :key="index + '-' + idx" v-if="idx > 0">
                                    <!-- ITEM SUGGESTION -->
                                    <template v-if="idx < skuLines[index].suggestion_lines.length">
                                        <td class="td vs-table--td" style="padding: 5px">
                                            {{ skuLines[index].suggestion_lines[idx].unit }}
                                        </td>
                                        <td class="td vs-table--td" style="padding: 5px">
                                            {{ skuLines[index].suggestion_lines[idx].quantity }}
                                        </td>
                                        <td class="td vs-table--td" style="padding: 5px">
                                            {{ skuLines[index].suggestion_lines[idx].storage_code }}
                                        </td>
                                        <td class="td vs-table--td" style="padding: 5px">
                                            {{ skuLines[index].suggestion_lines[idx].batch }}
                                        </td>
                                    </template>

                                    <template
                                        v-if="idx == skuLines[index].suggestion_lines.length && skuLines[index].suggestion_lines.length < skuLines[index].actual_lines.length">
                                        <td class="td vs-table--td" style="padding: 5px"
                                            :rowspan="calculateRowSpan(index, idx)">
                                        </td>
                                        <td class="td vs-table--td" style="padding: 5px"
                                            :rowspan="calculateRowSpan(index, idx)">
                                        </td>
                                        <td class="td vs-table--td" style="padding: 5px"
                                            :rowspan="calculateRowSpan(index, idx)">
                                        </td>
                                        <td class="td vs-table--td" style="padding: 5px"
                                            :rowspan="calculateRowSpan(index, idx)">
                                        </td>
                                    </template>

                                    <!-- actual picking -->
                                    <template v-if="idx < skuLines[index].actual_lines.length">
                                        <td class="td vs-table--td" style="padding: 5px">
                                            {{ skuLines[index].actual_lines[idx].selected_hu }}
                                        </td>
                                        <td class="td vs-table--td" style="padding: 5px">
                                            {{ skuLines[index].actual_lines[idx].qty }}
                                        </td>
                                        <td class="td vs-table--td" style="padding: 5px">
                                            {{ skuLines[index].actual_lines[idx].selected_storage_area }}
                                        </td>
                                        <td class="td vs-table--td" style="padding: 5px">
                                            {{ skuLines[index].actual_lines[idx].batch_number }}
                                        </td>
                                    </template>
                                </tr>
                            </template>
                        </template>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/1 w-full">
                <div style="
                    position: absolute;
                    left: 50%;
                    -ms-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
                ">
                    <vs-button class="ml-4 mt-2" type="border" color="danger" v-on:click="handleBack">Back</vs-button>
                </div>
            </div>
        </div>
    </vx-card>
</template>

<script>
import _ from "lodash";
import moment from "moment";

export default {
    components: {},
    data() {
        return {
            id: null,
            readonly: true,
            action: null,
            status: null,
            code: null,
            pickID: null,
            pickCode: null,
            selectedWarehouse: null,
            selectedTerritory: null,
            selectedSales: null,
            loadingDate: null,
            skuLines: [
                {
                    sku_code: null,
                    item_name: null,
                    option_hu: [],
                    suggestion_lines: [
                        {
                            suggest_id: 0,
                            unit: null,
                            quantity: 0,
                            storage_code: null,
                            batch: null,
                            expired_date: null,
                        },
                    ],
                    actual_lines: [
                        {
                            item_stock_id: 0,
                            selected_hu: null,
                            qty: 0,
                            selected_storage_area: null,
                            batch_number: null,
                            expired_date: null,
                        },
                    ],
                }
            ],
        }
    },
    mounted() {
        this.id = this.$route.params.id;
        if (this.id) {
            this.getData()
        }
    },
    methods: {
        getData() {
            this.action = "detail";
            this.$vs.loading();
            this.$http
                .get("/api/wms/v1/canvas/loading/get-picking/" + this.id + "/" + this.action)
                .then((resp) => {
                    if (resp.code == 200) {
                        this.status = resp.data.status
                        this.code = resp.data.code
                        this.selectedTerritory = resp.data.selected_territory
                        this.selectedWarehouse = resp.data.selected_warehouse
                        this.selectedSales = resp.data.selected_sales
                        this.loadingDate = resp.data.loading_date
                        this.pickID = resp.data.pick_id
                        this.pickCode = resp.data.pick_code
                        this.skuLines = resp.data.sku_lines
                        this.$vs.loading.close();
                    } else {
                        this.$vs.loading.close();
                    }
                })
                .catch((error) => {
                    this.$vs.loading.close();
                    console.log(error)
                    this.$vs.notify({
                        color: "danger",
                        title: "Error",
                        text: error,
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle",
                    });
                });
        },
        calculateRowSpan(index, idx) {
            let suggestionRowCount = this.skuLines[index].suggestion_lines.length;
            let actualRowCount = this.skuLines[index].actual_lines.length;

            if (suggestionRowCount < actualRowCount) {
                return actualRowCount - suggestionRowCount == 0
                    ? null
                    : actualRowCount - suggestionRowCount;
            }

            if (this.skuLines[index].suggestion_lines.length == 2) {
                let lastSuggestionIndex = suggestionRowCount > 0 ? suggestionRowCount - 1 : 0;
                let startActualIndex = lastSuggestionIndex;
                let totalRows = Math.max(suggestionRowCount, actualRowCount + startActualIndex);

                return totalRows;
            } else {
                return null;
            }
        },
        firstRowSpan(index) {
            return this.skuLines[index].suggestion_lines.length >
                this.skuLines[index].actual_lines.length
                ? this.skuLines[index].suggestion_lines.length
                : this.skuLines[index].actual_lines.length;
        },
        handleBack() {
            this.$router.push({
                name: "canvas.picking",
                params: {status: 2}
            });
        }
    },
    computed: {
        formatDate: () => {
            return (val) => moment.utc(val).format("YYYY-MM-DD")
        },
    },
}
</script>